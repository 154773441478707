import { getClientFromUrl } from '../app/utilities/env-utils';
import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  production: false,
  apiEndpoint: 'http://localhost:5000/api',
  client: getClientFromUrl(),
  runLocal: false,
  webConfig: {},
  logger: {
    level: NgxLoggerLevel.LOG
  },
  bambooBuildNumber: '3825',
  bambooBuildTimeStamp: '2024-10-15T12:07:04.605-05:00'
};
